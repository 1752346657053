export default () => {

$('.slider').slick({
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplaySpeed: 4000,
  speed: 1500,
  centerMode: true,
  fade: true,
  dots: false,
  arrows: false,
  infinite: true,
  pauseOnHover: false,
  swipe: true,
  swipeToSlide: true,
  responsive: [
  {
    breakpoint: 768,
    settings: {
      //variableWidth: true,
      //fade: false,
    }
  }]
});

};