export default () => {

  var options = {
    pageTopBtn : 'toTop',
    scrollSpeed : 8,
  };
  
  window.onload = function(){

    var btn = document.getElementById(options.pageTopBtn);

    var scroll_timer;
    function pageTop(x, y){
      if (y >= 1) {
        var scTop = Math.floor(y - (y / (options.scrollSpeed * 2)));
        window.scrollTo(x, scTop);
        scroll_timer = setTimeout(function(){pageTop(x, scTop)}, options.scrollSpeed); 
      } else {
        clearTimeout(scroll_timer);
        window.scrollTo(x, 0);
      }
    }
     
    btn.onclick = function(){
      var x = document.body.scrollLeft || document.documentElement.scrollLeft;
      var y = document.body.scrollTop || document.documentElement.scrollTop;
      pageTop(x, y);
      return false;
    }
   
  }

};
