export default () => {

$(() => {

  var flag = false;
  resizeFunc();

  $(window).resize(function(event) {
    resizeFunc();
  });

  function resizeFunc() {

    var hero = jQuery(".hero");
    var gnav = jQuery("#gnav");
    var main = jQuery("#main");
    var hero_offset = hero.height();

    var fixed_nav = function () {
      if (jQuery(window).scrollTop() > hero_offset) {
        if(!flag) {
          flag = true;
          gnav.addClass("is_active");
          main.addClass("is_active");
        }
      } else {
        flag = false;
        gnav.removeClass("is_active");
        main.removeClass("is_active");
      }
    };

    jQuery(window).scroll(fixed_nav);
    jQuery("body").bind("touchmove", fixed_nav);
    
  }

});

};