import Slick from './_slick.js';
Slick();

import Gnav from './_gnav';
import GnavToggleClass from './_gnavToggleClass';
import ImageSwitch from './_imageSwitch';
//import SmoothScroll from './_smoothScroll';
import ToTop from './_toTop';

Gnav();
GnavToggleClass();
ImageSwitch();
//SmoothScroll();
ToTop();
