export default () => {

$( () => {

  const gnav = $("#gnav");
  const list = $(".gnav_list");
  const mobileNav = $('.mnav');
  const header = $('#header');
  let header_offset = header.offset();
  let header_height = header.height();

  $(window).on('resize', function(){
    header_offset = header.offset();
    header_height = header.height();
  });

  mobileNav.click(function(e) {
    e.preventDefault();
    e.stopPropagation();
    if(list.is(":visible")) {
      $(this).removeClass('open');
      gnav.removeClass('open');
    } else {
      $(this).addClass('open');
      gnav.addClass('open');
    }
  });

  $(window).on('scroll',function(){
    if($(window).scrollTop() > header_offset.top + header_height - 1 ) {
      mobileNav.addClass('mainPosi');
    } else {
      mobileNav.removeClass('mainPosi');
    }
  })

});

};